export const PRODUCTION = {
  NODE_ENV: "production",
  AMBIE_ENV: "production",
  USE_REDUX_LOGGER: "false",
  WP_HOST: "https://kruo3njzbf.execute-api.eu-west-1.amazonaws.com/v1",
  WP_HOST_CF: "https://iaay4wmc48.execute-api.eu-west-1.amazonaws.com/v1",
  WP_HOST_CF_FRONTEND: "https://t4myl3f4pc.execute-api.eu-west-1.amazonaws.com/v1",
  WP_PORT: "443",
  WP_PREFIX: "/v1",
  WP_OLD_ADMIN_ROOT: "https://play.ambie.fm/",
  WP_OLD_ADMIN_URL: "https://play.ambie.fm/staff/",
  WP_OLD_ADMIN_URL_TRACKS: "https://play.ambie.fm/tracks/track/?q=",
  WP_AUDIO_PREFIX: "http://d1jixwkdwn00iq.cloudfront.net/media/",
  WP_MEDIA_CF_URL: "http://d1jixwkdwn00iq.cloudfront.net/",
  WP_WEBSOCKET_URL: "https://web-socket.ambie.fm:20000",
  WP_PLAYLIST_COVER: "https://play.ambie.fm/media/",
  WP_TRACK_NOT_IN_USE_ENDPOINT: "https://kruo3njzbf.execute-api.eu-west-1.amazonaws.com/v1/tracks/not-in-use",
  WP_TRACKS_ENDPOINT: "https://iaay4wmc48.execute-api.eu-west-1.amazonaws.com/v1/tracks",
  WP_COGNITO_IDENTITY_POOL: "eu-west-1:7ad82e31-9c63-4b11-ad1d-8249e69a7701",
  WP_IOT_HOST: "a117f4qbnqd0g2-ats.iot.eu-west-1.amazonaws.com",
  WP_IOT_POLICY: "productionOlympusBrowserPolicy",
  WP_AWS_REGION: "eu-west-1",
  WP_SF_DOMAIN: "https://ambie.lightning.force.com",
  WP_STREAMING_WEBSOCKET_URL: "https://web-socket.ambie.fm:20011",
  LOGIN_ENDPOINT: "https://y89y5hdjrh.execute-api.eu-west-1.amazonaws.com/v1",
  HOST_ROCK_API: "https://rock.ambie.fm",
  WEB_SOCKET_URL: "wss://rock.ambie.fm/events",
};
